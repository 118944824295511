import React from 'react';

const ConsentInformation = () => {
    return (
        <div className="max-w-2xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Consent Collection Process</h1>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">1. Overview</h2>
                <p>
                    At Product Images AI, we prioritize user privacy and ensure that all communications comply with relevant regulations. This document outlines our process for obtaining and documenting user consent for receiving SMS communications.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">2. Methods of Obtaining Consent</h2>
                <ul className="list-disc list-inside">
                    <li>
                        <strong>Online Sign-Up Forms:</strong> Users can opt in by entering their phone numbers on our website’s sign-up page, explicitly agreeing to receive messages. The sign-up page includes a checkbox labeled, “I agree to receive SMS communications from Product Images AI.”
                    </li>
                    <li>
                        <strong>Text-In Keywords:</strong> Users can opt in by texting a specific keyword (e.g., “JOIN”) to our designated number. This action signifies their consent to receive messages from us.
                    </li>
                </ul>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">3. Documentation of Consent</h2>
                <ul className="list-disc list-inside">
                    <li>
                        <strong>Online Forms:</strong> Each submission is time-stamped and stored securely in our database, including the user’s phone number and consent status.
                    </li>
                    <li>
                        <strong>Text-In Keywords:</strong> Incoming messages with opt-in keywords are logged with timestamps, and the associated phone numbers are added to our consent database.
                    </li>
                </ul>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">4. Confirmation Messages</h2>
                <p>
                    After consent is obtained, we send a confirmation message that includes a welcome message, instructions to opt out (e.g., “Reply STOP to unsubscribe”), message frequency, and possible carrier data charges.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">5. Opt-Out Process</h2>
                <p>
                    Users can revoke consent at any time by replying with “STOP.” We immediately remove the user’s phone number from our messaging list and send a confirmation of the opt-out.
                </p>
            </section>

            <section>
                <h2 className="text-xl font-semibold">6. Retention of Consent Records</h2>
                <p>
                    We retain proof of consent records for a minimum of 2 years, even after a user opts out, to demonstrate compliance with Twilio’s messaging policies and applicable laws.
                </p>
            </section>
        </div>
    );
};

export default ConsentInformation;

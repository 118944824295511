import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import Navigation from './Navigation.js'
import makeApiRequest from './util/diffute.js';

export default function Example() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        pretrained_model_name: 'runwayml/stable-diffusion-v1-5',
        pretrained_vae_name: '',
        class_prompt: '',
        training_resolution: 512,
        learning_rate: '1e-6',
        max_train_steps: 800,
        train_batch_size: 1,
        optimization_type: '',
        num_class_images: 200,
        mixed_precision: 'fp16',
        unique_id: '',
        with_prior_preservation: false,
        prior_loss_weight: 1.0,
        seed: null,
        center_crop: false,
        train_text_encoder: true,
        sample_batch_size: 4,
        num_train_epochs: 1,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            const responseData = await makeApiRequest('training', 'POST', formData);
            console.log('API response:', responseData);

            navigate(`/project/` + responseData.project_id);

        } catch (error) {
            console.error('API request error:', error);
        }
    };

    return (
        <div>
            <Navigation />
            <div className="relative">
                {/* ... Other relative div contents */}
            </div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-3xl">
                    <div>
                        <form onSubmit={handleFormSubmit}>
                            <div className="space-y-12">
                                <div className="border-b border-gray-900/10 pb-12">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">New Training</h2>
                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="pretrained_model_name" className="block text-sm font-medium leading-6 text-gray-900">
                                                Pretrained Model Name
                                            </label>
                                            <div className="mt-2">
                                                <select
                                                    name="pretrained_model_name"
                                                    id="pretrained_model_name"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    value={formData.pretrained_model_name}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="runwayml/stable-diffusion-v1-5">runwayml/stable-diffusion-v1-5</option>
                                                    {/* Add other options here */}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="pretrained_vae_name" className="block text-sm font-medium leading-6 text-gray-900">
                                                Pretrained VAE Name
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="pretrained_vae_name"
                                                    id="pretrained_vae_name"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="class_prompt" className="block text-sm font-medium leading-6 text-gray-900">
                                                Class Prompt
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="class_prompt"
                                                    id="class_prompt"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="training_resolution" className="block text-sm font-medium leading-6 text-gray-900">
                                                Training Resolution
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="number"
                                                    name="training_resolution"
                                                    id="training_resolution"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="class_prompt" className="block text-sm font-medium leading-6 text-gray-900">
                                                Class Prompt
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="class_prompt"
                                                    id="class_prompt"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="training_resolution" className="block text-sm font-medium leading-6 text-gray-900">
                                                Training Resolution
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="number"
                                                    name="training_resolution"
                                                    id="training_resolution"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="class_prompt" className="block text-sm font-medium leading-6 text-gray-900">
                                                Class Prompt
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="class_prompt"
                                                    id="class_prompt"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="training_resolution" className="block text-sm font-medium leading-6 text-gray-900">
                                                Training Resolution
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="number"
                                                    name="training_resolution"
                                                    id="training_resolution"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="class_prompt" className="block text-sm font-medium leading-6 text-gray-900">
                                                Class Prompt
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="class_prompt"
                                                    id="class_prompt"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="training_resolution" className="block text-sm font-medium leading-6 text-gray-900">
                                                Training Resolution
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="number"
                                                    name="training_resolution"
                                                    id="training_resolution"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="class_prompt" className="block text-sm font-medium leading-6 text-gray-900">
                                                Class Prompt
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="class_prompt"
                                                    id="class_prompt"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="training_resolution" className="block text-sm font-medium leading-6 text-gray-900">
                                                Training Resolution
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="number"
                                                    name="training_resolution"
                                                    id="training_resolution"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="class_prompt" className="block text-sm font-medium leading-6 text-gray-900">
                                                Class Prompt
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="class_prompt"
                                                    id="class_prompt"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="training_resolution" className="block text-sm font-medium leading-6 text-gray-900">
                                                Training Resolution
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="number"
                                                    name="training_resolution"
                                                    id="training_resolution"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Continue adding other fields */}
                                </div>
                            </div>
                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { PaperClipIcon } from '@heroicons/react/20/solid'
import Navigation from './Navigation.js'
import DeleteFile from './DeleteFile';
import DropDownDelete from './components/DropdownDelete';

import makeApiRequest from './util/diffute.js';

export default function Upload() {

    const [media, setMedia] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [error, setError] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemsToDelete, setItemsToDelete] = useState([]);

    const [isMultiDeleteModalOpen, setIsMultiDeleteModalOpen] = useState(false);





    const [fileToDelete, setFileToDelete] = useState(null);

    const { project_id } = useParams();

    const fetchData = async () => {
        try {
            const response = await makeApiRequest(`project/${project_id}/media`);
            setMedia(response);
            setSelectedItems(new Array(response.length).fill(false));

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        fetchData(); // Call fetchData on component mount
    }, [uploadedFiles, error]);

    // const handleSelectAllChange = (e) => {
    //     const checked = e.target.checked;
    //     const updatedItems = new Array(media.length).fill(checked);
    //     setSelectedItems(updatedItems);

    //     const updatedItemsToDelete = checked ? media.map(item => item.url) : [];
    //     setItemsToDelete(updatedItemsToDelete);
    // };

    const handleSelectAllChange = (e) => {
        const checked = e.target.checked;
        const updatedItems = new Array(media.length).fill(checked);
        setSelectedItems(updatedItems);

        const updatedItemsToDelete = checked ? media.map(item => item.url) : [];
        setItemsToDelete(updatedItemsToDelete);
    };



    const handleDelete = () => {
        // itemsToDelete already contains the URLs of the selected items
        setMedia(prevMedia => prevMedia.filter(item => !itemsToDelete.includes(item.url)));
    }



    const handleDeleteCompleted = () => {
        // Refresh the media list by calling fetchData
        fetchData();
        // Close the multi-delete modal
        setIsMultiDeleteModalOpen(false);
        // Close the single delete modal
        setIsDeleteModalOpen(false);
    }


    // const handleSelectChange = (index) => {
    //     const updatedItems = [...selectedItems];
    //     updatedItems[index] = !updatedItems[index];
    //     setSelectedItems(updatedItems);

    //     const updatedItemsToDelete = updatedItems.map((isSelected, idx) => isSelected ? media[idx].url : null).filter(url => url !== null);
    //     setItemsToDelete(updatedItemsToDelete);
    // };


    const handleSelectChange = (index) => {
        const updatedItems = [...selectedItems];
        updatedItems[index] = !updatedItems[index];
        setSelectedItems(updatedItems);

        const updatedItemsToDelete = updatedItems.map((isSelected, idx) => isSelected ? media[idx].url : null).filter(url => url !== null);
        setItemsToDelete(updatedItemsToDelete);
    };







    const handleDrop = async (e) => {
        e.preventDefault();
        setIsDragOver(false);
        setIsUploading(true);

        let files = [...e.dataTransfer.files];

        // Filter out non-image files
        files = files.filter(file => file.type.startsWith('image/'));

        if (files.length === 0) {
            setError("Please upload valid image files.");
            return;
        }

        try {
            const formData = new FormData();

            // Append each file to the formData
            for (let file of files) {
                formData.append('files', file);
                console.log("File Object:", file);
            }

            // Make a single API request with all the files
            await makeApiRequest(`project/${project_id}/media`, 'POST', formData, true);

            setUploadedFiles(prevFiles => [...prevFiles, ...files]);
            setError(null);
        } catch (err) {
            setError(err.message);
        }

        setIsUploading(false);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    }

    const secondaryNavigation = [
        { name: 'Projects', href: `/project/${project_id}`, current: true },
        { name: 'Training Images', href: `/project/${project_id}/media`, current: false },
    ]

    return (
        <div>
            <Navigation />
            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900"></span>
                </div>
            </div>

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-3xl">
                    <div>
                        {/* Secondary navigation */}
                        <nav className="flex overflow-x-auto border-b border-white/10 py-4">
                            <ul
                                role="list"
                                className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
                            >
                                {secondaryNavigation.map((item) => (
                                    <li key={item.name}>
                                        <a href={item.href} className={item.current ? 'text-indigo-400' : ''}>
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </nav>

                        <div className="col-span-full">
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                Upload unique images, but all of a single product (minimum of 7 required to begin training)
                            </label>
                            <div
                                className={`mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 ${isDragOver ? 'bg-blue-100' : ''}`}
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <div className="text-center">
                                    <PhotoIcon className={`mx-auto h-12 w-12 ${isUploading ? 'text-blue-300 animate-pulse' :
                                        isDeleting ? 'text-red-600 animate-pulse' :
                                            'text-gray-300'
                                        }`}
                                        aria-hidden="true" />
                                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                        <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                        </label>
                                        <p className="pl-1">Drag and drop images here</p>
                                    </div>
                                    <p className="text-xs leading-5 text-gray-600">PNG or JPG up to 10MB</p>
                                </div>
                            </div>

                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
                                <div className="flex justify-between items-center mb-4">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Images</dt>
                                    <a href={`/project/${project_id}`} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-4 border border-blue-500 hover:border-transparent rounded">
                                        Finish uploading for now
                                    </a>
                                </div>

                                {error && <p className="text-red-500 mt-4">{error}</p>}
                                {media.length > 1 ? (
                                    <DropDownDelete onDeleteSelected={() => setIsMultiDeleteModalOpen(true)} />
                                ) : null}

                                <dd className="mt-2 text-sm text-gray-900">
                                    <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                        <div className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 flex w-0 flex-1 items-center">

                                            {media.length > 1 ? (
                                                <div className="flex items-center">
                                                    <input
                                                        id="select-all"
                                                        name="select-all"
                                                        type="checkbox"
                                                        checked={selectedItems.every(item => item)}
                                                        onChange={handleSelectAllChange}
                                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                        {media.map((item, index) => (
                                            <li key={item.url} className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                <div className="flex w-0 flex-1 items-center">

                                                    <div className="flex items-center">
                                                        <input
                                                            id="select-image"
                                                            name="select-image"
                                                            type="checkbox"
                                                            checked={selectedItems[index]}
                                                            onChange={() => handleSelectChange(index)}
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                    </div>
                                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                        <span className="truncate font-medium">{item.url.split('/').pop()}</span>
                                                        <span className="flex-shrink-0 text-gray-400">{(item.size / 1048576).toFixed(2)}mb</span>
                                                    </div>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                    <button
                                                        onClick={() => {
                                                            setFileToDelete(item.url);
                                                            setIsDeleteModalOpen(true);
                                                        }}
                                                        className="font-medium text-red-600 hover:text-red-500"
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </dd>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isDeleteModalOpen && (
                <DeleteFile
                    isOpen={true}
                    onCancel={() => {
                        setIsDeleteModalOpen(false);
                        setFileToDelete(null);
                    }}
                    // onDelete={() => {
                    //     // Call the API here to delete the individual file
                    //     setIsDeleting(true);
                    //     setIsDeleteModalOpen(false);
                    //     // ... (make the API call here to delete `fileToDelete`)
                    // }}
                    // onDelete={() => {
                    //     handleDelete([fileToDelete]);
                    // }}
                    onDeleteCompleted={() => {
                        setMedia(prevMedia => prevMedia.filter(item => item.url !== fileToDelete));
                        setError(null);
                        setIsDeleteModalOpen(false);
                        setIsDeleting(false);
                    }}
                    project_id={project_id}
                    fileUrl={fileToDelete}
                    setIsDeleting={setIsDeleting}
                    isDeleting={isDeleting}
                />
            )}

            {isMultiDeleteModalOpen && (
                <DeleteFile
                    isOpen={true}
                    onCancel={() => {
                        setIsMultiDeleteModalOpen(false);
                    }}
                    // onDelete={() => {
                    //     // Call the API here to delete the selected items
                    //     setIsDeleting(true);
                    //     setIsMultiDeleteModalOpen(false);
                    //     handleDelete(selectedItems);
                    // }}
                    itemsToDelete={itemsToDelete}
                    // onDelete={() => {
                    //     setIsDeleting(true);
                    //     setIsMultiDeleteModalOpen(false);
                    //     itemsToDelete = { itemsToDelete }
                    //     handleDelete(itemsToDelete);
                    // }}
                    setIsDeleting={setIsDeleting}
                    isDeleting={isDeleting}
                    onDeleteCompleted={handleDeleteCompleted}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    isMultiDelete={true}
                    project_id={project_id}
                />
            )}



            {/* {isMultiDeleteModalOpen && isDeleteModalOpen && (
                // <DeleteFile
                //     isOpen={true}
                //     onCancel={() => {
                //         setIsDeleteModalOpen(false);
                //         setFileToDelete(null);
                //         setIsMultiDeleteModalOpen(false);
                //     }}
                //     onDelete={() => {
                //         // Call the delete API here
                //         setIsDeleting(true);
                //         setIsDeleteModalOpen(false);
                //         setFileToDelete(null);
                //         handleDelete(selectedItems);

                //     }}
                //     onDeleteCompleted={() => {
                //         setMedia(prevMedia => prevMedia.filter(item => item.url !== fileToDelete));
                //         setError(null);
                //         setIsDeleteModalOpen(false);
                //         setIsDeleting(false);
                //         handleDeleteCompleted();

                //     }}
                //     selectedItems={selectedItems}
                //     setSelectedItems={setSelectedItems}
                //     isMultiDelete={true}
                //     project_id={project_id}
                //     fileUrl={fileToDelete}
                // />
                <DeleteFile
                    isOpen={true}
                    onCancel={() => {
                        setIsDeleteModalOpen(false);
                        setFileToDelete(null);
                        setIsMultiDeleteModalOpen(false);
                    }}
                    onDelete={() => handleDelete(selectedItems)}
                    onDeleteCompleted={handleDeleteCompleted}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    isMultiDelete={isMultiDeleteModalOpen}
                    project_id={project_id}
                    fileUrl={fileToDelete}
                />
            )} */}

        </div>
    )
}
